// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_link__xIhZh {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7c7c7c;
  cursor: pointer;
  padding: 18px 10px;
  margin: 0 19px;
  margin-top: -3px;
  border-top: 4px solid transparent;
  box-sizing: border-box;
}

.Header_link__xIhZh:hover {
  color: #536ce8;
  border-top-color: #016ce8;
}

.Header_member-login-btn__hXAKY {
  border-radius: 4px;
  padding: 10px 40px;
  background-color: #016ce8;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.Header_contact-link__4Mt7o:hover {
  color: #536ce8;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,iCAAiC;EACjC,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".link {\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  color: #7c7c7c;\n  cursor: pointer;\n  padding: 18px 10px;\n  margin: 0 19px;\n  margin-top: -3px;\n  border-top: 4px solid transparent;\n  box-sizing: border-box;\n}\n\n.link:hover {\n  color: #536ce8;\n  border-top-color: #016ce8;\n}\n\n.member-login-btn {\n  border-radius: 4px;\n  padding: 10px 40px;\n  background-color: #016ce8;\n  color: #fff;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n}\n\n.contact-link:hover {\n  color: #536ce8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `Header_link__xIhZh`,
	"member-login-btn": `Header_member-login-btn__hXAKY`,
	"contact-link": `Header_contact-link__4Mt7o`
};
export default ___CSS_LOADER_EXPORT___;
