import React from "react";
import logoImage from "../../assets/images/logo.png";
import styles from "./Header.module.css";

const Header = () => {
  const headerLinks = [
    {
      slug: "home",
      name: "Home",
      link: "https://idprotectus.com/",
    },
    {
      slug: "about",
      name: "About",
      link: "https://idprotectus.com/about/",
    },
    {
      slug: "faq",
      name: "FAQs",
      link: "https://idprotectus.com/faqs/",
    },
    {
      slug: "resources",
      name: "Resources",
      link: "https://idprotectus.com/resources/",
    },
    {
      slug: "blog",
      name: "Blog",
      link: "https://idprotectus.com/blog/",
    },
    {
      slug: "contact",
      name: "Contact",
      link: "https://idprotectus.com/contact/",
    },
  ];

  return (
    <div className="h-[110px]">
      <div
        class="bg-white border-gray-200 white:bg-gray-900"
        style={{
          borderBottom: "2px solid rgba(1, 108, 232, 0.2)",
        }}
      >
        <div class="flex flex-wrap items-center mx-auto px-[3.7%] mt-[20px] mb-[10px] gap-9">
          <a
            href="tel:1-800-934-4957"
            className={`${styles["contact-link"]} font-normal text-base leading-6 text-black cursor-pointer`}
          >
            1-800-934-4957
          </a>
          <a
            href="mailto:support@idprotectus.com"
            className={`${styles["contact-link"]} font-normal text-base leading-6 text-black cursor-pointer`}
          >
            support@idprotectus.com
          </a>
        </div>
      </div>
      <nav class="bg-white border-gray-200 white:bg-gray-900">
        <div class="flex flex-wrap justify-between items-center mx-auto px-[3.7%]">
          <div>
            <img
              className="cursor-pointer"
              src={logoImage}
              alt="logo"
              onClick={() => {
                window.location.href = "https://idprotectus.com/";
              }}
            />
          </div>
          <div className={"flex items-center"}>
            {headerLinks?.map((link) => (
              <p
                key={link.slug}
                className={`${styles.link}`}
                onClick={() => {
                  window.location.href = link.link;
                }}
              >
                {link?.name}
              </p>
            ))}
            <div className="pl-4">
              <button className={`${styles["member-login-btn"]}`}>
                Member Login
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
