import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { apiService } from "../services/apiService";
import authReducer from "../features/authSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  [apiService.reducerPath]: apiService.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(apiService.middleware)
});

export default store;
