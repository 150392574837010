// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_login-header__Kchxx {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #002247;
}

.Login_label__1UlG- {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #002247;
}

.Login_inputField__Nf228 {
  /* width: 532px; */
  height: 55.08px;
  border: none;
  border-bottom: 1.5px solid #e9e9e9;
  font-size: 20px;
  color: #002247;
  padding: 0px 10px;
  border-radius: 5.01px;
  appearance: none;
  background-color: transparent;
  &::-webkit-autofill {
    background-color: transparent !important;
  }
  &:-webkit-autofill {
    background-color: transparent !important;
  }
  &::-ms-clear {
    display: none;
  }
}

.Login_inputField__Nf228::placeholder {
  opacity: 0.5;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #002247;
}

.Login_inputField__Nf228:focus {
  outline: none;
}

.Login_login-btn__cIxJW {
  width: 532px;
  height: 57.58px;
  border-radius: 5.01px;
  background-color: #016ce8;
  color: #fff;
  font-size: 20.03px;
  line-height: 30.04px;
}

.Login_error-message__-igFq {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #e36b57;
}

.Login_login-bg__DwgOf {
  width: 919px;
  height: 950px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,kCAAkC;EAClC,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,qBAAqB;EAGrB,gBAAgB;EAChB,6BAA6B;EAC7B;IACE,wCAAwC;EAC1C;EACA;IACE,wCAAwC;EAC1C;EACA;IACE,aAAa;EACf;AACF;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,qBAAqB;EACrB,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".login-header {\n  font-weight: 600;\n  font-size: 30px;\n  line-height: 45px;\n  color: #002247;\n}\n\n.label {\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 27px;\n  color: #002247;\n}\n\n.inputField {\n  /* width: 532px; */\n  height: 55.08px;\n  border: none;\n  border-bottom: 1.5px solid #e9e9e9;\n  font-size: 20px;\n  color: #002247;\n  padding: 0px 10px;\n  border-radius: 5.01px;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  background-color: transparent;\n  &::-webkit-autofill {\n    background-color: transparent !important;\n  }\n  &:-webkit-autofill {\n    background-color: transparent !important;\n  }\n  &::-ms-clear {\n    display: none;\n  }\n}\n\n.inputField::placeholder {\n  opacity: 0.5;\n  font-weight: 400;\n  font-size: 20px;\n  line-height: 30px;\n  color: #002247;\n}\n\n.inputField:focus {\n  outline: none;\n}\n\n.login-btn {\n  width: 532px;\n  height: 57.58px;\n  border-radius: 5.01px;\n  background-color: #016ce8;\n  color: #fff;\n  font-size: 20.03px;\n  line-height: 30.04px;\n}\n\n.error-message {\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  color: #e36b57;\n}\n\n.login-bg {\n  width: 919px;\n  height: 950px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-header": `Login_login-header__Kchxx`,
	"label": `Login_label__1UlG-`,
	"inputField": `Login_inputField__Nf228`,
	"login-btn": `Login_login-btn__cIxJW`,
	"error-message": `Login_error-message__-igFq`,
	"login-bg": `Login_login-bg__DwgOf`
};
export default ___CSS_LOADER_EXPORT___;
