import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = `https://prod-api.legal-api.com/`;

export const apiService = createApi({
  reducerPath: "apiService",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", `application/json`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    postData: builder.mutation({
      query: (data) => {
        return {
          url: "api/customer_find",
          method: "POST",
          body: {
            last_name: data?.username,
            phone: data?.password,
            domain: data?.domain,
          },
        };
      },
    }),
  }),
});

export const { usePostDataMutation } = apiService;
