// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer-container__eZnXC {
  background-color: #002247;
  margin-top: 60px;
}

.Footer_footer-inner-container__PjGoz {
  padding-top: 100px;
  padding-right: 240px;
  padding-bottom: 50px;
  padding-left: 180px;
}

.Footer_protection-text__RyO8J {
  margin-top: 30px;
  color: #f0f0f0c7;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  font-style: normal;
}

.Footer_links-header-text__1akcG {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 28px;
  font-weight: 500;
  text-transform: capitalize;
  font-style: normal;
  line-height: 35.063px;
  margin-bottom: 10px;
}

.Footer_footer-link__IPChy {
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
  font-style: normal;
  line-height: 30px;
  cursor: pointer;
  color: #ffffff;
  transition: color 0.3s;
  margin-top: 10px;
}

.Footer_footer-link__IPChy:hover {
  color: #536ce8;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,kCAAkC;EAClC,eAAe;EACf,gBAAgB;EAChB,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kCAAkC;EAClC,eAAe;EACf,gBAAgB;EAChB,0BAA0B;EAC1B,kBAAkB;EAClB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,0BAA0B;EAC1B,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,cAAc;EACd,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".footer-container {\n  background-color: #002247;\n  margin-top: 60px;\n}\n\n.footer-inner-container {\n  padding-top: 100px;\n  padding-right: 240px;\n  padding-bottom: 50px;\n  padding-left: 180px;\n}\n\n.protection-text {\n  margin-top: 30px;\n  color: #f0f0f0c7;\n  font-family: \"Poppins\", Sans-serif;\n  font-size: 20px;\n  font-weight: 400;\n  text-transform: capitalize;\n  font-style: normal;\n}\n\n.links-header-text {\n  color: #ffffff;\n  font-family: \"Poppins\", Sans-serif;\n  font-size: 28px;\n  font-weight: 500;\n  text-transform: capitalize;\n  font-style: normal;\n  line-height: 35.063px;\n  margin-bottom: 10px;\n}\n\n.footer-link {\n  font-size: 24px;\n  font-weight: 400;\n  text-transform: capitalize;\n  font-style: normal;\n  line-height: 30px;\n  cursor: pointer;\n  color: #ffffff;\n  transition: color 0.3s;\n  margin-top: 10px;\n}\n\n.footer-link:hover {\n  color: #536ce8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer-container": `Footer_footer-container__eZnXC`,
	"footer-inner-container": `Footer_footer-inner-container__PjGoz`,
	"protection-text": `Footer_protection-text__RyO8J`,
	"links-header-text": `Footer_links-header-text__1akcG`,
	"footer-link": `Footer_footer-link__IPChy`
};
export default ___CSS_LOADER_EXPORT___;
