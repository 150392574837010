import React from "react";
import styles from "./Footer.module.css";
import whiteLogo from "../../assets/images/white-logo.png";

const Footer = () => {
  const FooterLinks = [
    {
      slug: "terms-of-service",
      name: "Terms Of Service",
      link: "https://idprotectus.com/terms-conditions/",
    },
    {
      slug: "privacy-policy",
      name: "Privacy Policy",
      link: "https://idprotectus.com/privacy-policy/",
    },
    {
      slug: "contact",
      name: "Contact",
      link: "https://idprotectus.com/contact/",
    },
  ];
  return (
    <div className={styles["footer-container"]}>
      <div className={`${styles["footer-inner-container"]}`}>
        <div className="flex justify-between">
          <div className="flex-col">
            <img
              width="280"
              height="85"
              src={whiteLogo}
              onClick={() => {
                window.location.href = "https://idprotectus.com/";
              }}
            />
            <p className={styles["protection-text"]}>
              Enhanced Identity Protection Services
            </p>
          </div>
          <div className="flex-col">
            <p className={styles["links-header-text"]}>Links</p>
            {FooterLinks?.map((link) => (
              <p
                className={styles["footer-link"]}
                key={link.slug}
                onClick={() => {
                  window.location.href = link.link;
                }}
              >
                {link.name}
              </p>
            ))}
          </div>
        </div>
        <p className={styles["protection-text"]} style={{ marginTop: "80px" }}>
          &copy; 2023 ID Protect Us. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
