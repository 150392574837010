import { createSlice } from "@reduxjs/toolkit";

let userInfo = {};

if (
  localStorage.getItem("userInfo") &&
  localStorage.getItem("userInfo") !== "undefined" &&
  localStorage.getItem("userInfo") !== "null"
) {
  userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : {};
}

const initialState = {
  userInfo,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      if (
        action?.payload?.error?.data?.code === -1 ||
        action?.payload?.data?.apiResponse?.ssokey === ""
      ) {
        localStorage.setItem("userInfo", JSON.stringify({}));
      } else {
        state.userInfo = action.payload;
        localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
      }
    },
  },
});

export const { setUserInfo } = authSlice.actions;

export default authSlice.reducer;
